<template>
  <div>
    <filter-slot
      :filter="visibleFilters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reset-all-filters="resetAllFilters()"
      @reload="$refs.clientsList.refresh()"
    >
      <template #buttons>
        <!-- Button Sms -->
        <b-button
          variant="success"
          class="ml-1"
          :disabled="lengthClients"
          @click="openModalSendSms"
        >
          <feather-icon icon="MessageCircleIcon" class="mr-50" />Send SMS
        </b-button>
        <!-- Button Send Email -->
        <b-button
          variant="success"
          class="ml-1"
          :disabled="lengthClients"
          @click="openModalSendEmail"
        >
          <feather-icon icon="MailIcon" class="mr-50" />Send Email
        </b-button>
      </template>
      <template #custom-vselect>
        <FilterStatusAccount :filters="filters[4]" />
      </template>
      <template #table>
        <b-table
          ref="clientsList"
          slot="table"
          :busy.sync="isBusy"
          :fields="visibleFields"
          :items="search"
          :filter="visibleFilters"
          :per-page="paginate.perPage"
          :current-page="paginate.currentPage"
          no-provider-filtering
          responsive="sm"
          small
          show-empty
          sticky-header="65vh"
        >
          <template #head(selected)>
            <b-form-checkbox v-model="selectAll" @input="selectedAll" />
          </template>
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading...</strong>
            </div>
          </template>
          <!-- Selected -->
          <template #cell(selected)="data">
            <b-form-group>
              <b-form-checkbox
                v-model="clientsSms"
                :value="{
                  id: data.item.account_id,
                  name: data.item.lead_name,
                }"
                :name="data.item.account_id"
                class="mt-1"
              />
            </b-form-group>
          </template>
          <template #cell(lead_name)="data">
            <div
              class="d-flex flex-column justify-content-center align-items-start"
            >
              <router-link
                class
                v-if="moduleId === 12"
                target="_blank"
                :to="`/ti/clients/account/${data.item.account_id}`"
              >
                {{ data.item.lead_name }}
              </router-link>
              <router-link
                class
                v-if="moduleId === 3"
                target="_blank"
                :to="`/bussiness/clients/account/${data.item.account_id}`"
              >
                {{ data.item.lead_name }}
              </router-link>
              <status-account
                :account="data.item"
                :text="true"
              ></status-account>
              <span v-if="data.item.user_responsible" class="text-warning">
                <tabler-icon
                  :icon="
                    data.item.user_responsible === currentUser.user_id
                      ? 'StarIcon'
                      : 'LockIcon'
                  "
                  size="15"
                  style="margin-left: 5px"
                />
                <strong>CEO's client</strong>
              </span>
            </div>
          </template>
          <template #cell(mobile)="data">
            <p class="mb-0 font-weight-bold">
              {{ data.item.mobile }}
            </p>
          </template>
          <template #cell(account)="data">
            <div>
              {{ data.item.account }}
            </div>

            <div>
              <b-badge v-if="data.item.claims != 0" variant="light-info">
                IN CLAIM ({{ data.item.claims }})
              </b-badge>
            </div>
          </template>
          <template #cell(fee_charges)="data">
            <div
              class="d-flex flex-column justify-content-center align-items-start"
              style="padding-bottom: 4px"
            >
              {{ data.item.total_charge | formatMoney }}
            </div>
          </template>
          <template #cell(mp)="data">
            <div
              class="d-flex flex-column justify-content-start align-items-start"
              style="padding-bottom: 4px"
            >
              {{ data.item.monthly_amount | formatMoney }}
            </div>
          </template>
          <template #cell(lp)="data">
            <div
              class="d-flex flex-column justify-content-start align-items-start"
              style="position: relative"
            >
              <span style="display: flex">
                <b-img
                  v-if="data.item.state_lp == 1"
                  :src="`${baseImg}/assets/images/paragon/last_payment/lastpayment-out.ico`"
                  style="
                    margin-right: 5px;
                    height: 15px;
                    margin-top: 2px;
                    width: 15px;
                  "
                />
                <b-img
                  v-else
                  :src="`${baseImg}/assets/images/paragon/last_payment/lastpayment-in.ico`"
                  style="
                    margin-right: 5px;
                    height: 15px;
                    margin-top: 2px;
                    width: 15px;
                  "
                />
                {{
                  data.item.last_payment == "-"
                    ? ""
                    : data.item.last_payment | myGlobal
                }}
              </span>
            </div>
          </template>
          <template #cell(pt)="data">
            <div>
              <ul style="padding-left: 0px; margin-bottom: 0px">
                <li style="list-style: none">
                  <status-payment :account="data.item" />
                </li>
              </ul>
            </div>
          </template>
          <template #head(advisor)>
            <span v-if="moduleParagon">ASSESOR</span>
            <span v-if="!moduleParagon">ADVISOR</span>
          </template>
          <template #cell(advisor)="data">
            <div
              class="d-flex flex-column justify-content-start align-items-start"
            >
              <span style="padding-bottom: 4px">
                <feather-icon
                  v-if="data.item.state_advisor === 1"
                  icon="CircleIcon"
                  size="13"
                  :style="`color: #00CC00; border-color: #00CC00; background: #00CC00; border-radius: 50%; margin-bottom: 2px; margin-right: 5px;`"
                />
                <feather-icon
                  v-if="data.item.state_advisor === 2"
                  icon="CircleIcon"
                  size="13"
                  :style="`color: #00CC00; border-color: #00CC00; background: #00CC00; border-radius: 50%; margin-bottom: 2px; margin-right: 5px;`"
                />
                <feather-icon
                  v-if="data.item.state_advisor === 0"
                  icon="CircleIcon"
                  size="13"
                  :style="`color: rgb(204, 204, 204); border-color: #00CC00; background: rgb(204, 204, 204); border-radius: 50%; margin-bottom: 2px; margin-right: 5px;`"
                />
                <span>{{ data.item.advisor_name }}</span></span
              >
            </div>
          </template>
          <template #cell(ln)="data">
            <div
              class="d-flex flex-column justify-content-start align-items-start"
            >
              <span
                :style="
                  data.item.s_note <= 1
                    ? ''
                    : data.item.s_note <= 2
                    ? 'color: #FF8000'
                    : 'color:#FF0000'
                "
                style="padding-bottom: 4px"
                >{{ data.item.last_note | myGlobal }}
              </span>
            </div>
          </template>
          <template #cell(ut)="data">
            <div
              class="d-flex flex-column justify-content-start align-items-start"
              style="margin-left: 10px"
            >
              <span
                :style="
                  data.item.task <= 5
                    ? 'color:#000000; position: relative;'
                    : data.item.task <= 10
                    ? 'color: #FF8000; position: relative;'
                    : 'color:#FF0000; position: relative;'
                "
              >
                <b-img
                  :src="`${baseImg}/assets/images/paragon/tasks/uncompletetasks.ico`"
                  style="
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                  "
                />
                <span
                  style="font-size: 11px"
                  :style="isDarkSkin ? 'color: #fff' : ''"
                  >{{ data.item.task }}</span
                >
              </span>
            </div>
          </template>
          <template #cell(creation_date)="data">
            <span>{{ data.item.created_at | myGlobal }}</span>
          </template>
          <!-- Column SERVICES -->
          <template
            v-if="
              currentUser.role_id == 1 ||
              currentUser.role_id == 2 ||
              currentUser.role_id == 3 ||
              currentUser.role_id == 17
            "
            #cell(services)="data"
          >
            <actions-table
              v-if="
                data.item.user_responsible == currentUser.user_id ||
                data.item.user_responsible == null
              "
              :options="actionsOptions"
              :row-data="data.item"
              @onRowDelete="onRowDelete"
              @onRowEdit="onRowEdit"
              @onRowProcess="onRowProcess"
              @modalChangeService="modalChangeService"
              @modalAddService="modalAddService"
            />
          </template>
          <!-- Column Actions -->
          <template #cell(actions)="data">
            <actions-table
              v-if="
                data.item.user_responsible == currentUser.user_id ||
                data.item.user_responsible == null
              "
              :options="actionsOptionsPersonal"
              :row-data="data.item"
              @modalHistorySmsOpen="openModalHistorySms"
              @modalHistoryEmailOpen="openModalHistoryEmail"
            />
          </template>
        </b-table>
      </template>
    </filter-slot>
    <!-- Modal send sms -->
    <modal-send-sms
      v-if="modalSendSms"
      :modal-send-sms="modalSendSms"
      :name-leads="clientsSms[0]"
      :typesms="0"
      @closeModal="closeSendSms"
    />
    <!-- Modal Send Email -->
    <modal-send-email
      v-if="modalSendEmail"
      :modalSendEmail="modalSendEmail"
      :nameLeads="clientsSms"
      :typesms="0"
      @closeModal="closeSendSms"
    />
    <!-- Modal Add Services -->
    <modal-add-service
      v-if="modalOpenAddService"
      :modal-open-add-service="modalOpenAddService"
      :details-client="detailsClient"
      :type-modal="typeModal"
      @hideModal="closeModalService"
      @changeProgram="openModalProgram"
    />
    <!-- modal HISTORY SMS -->
    <b-modal
      id="modal-history-sms"
      ok-only
      modal-class="modal-primary"
      title-class="text-white h4"
      centered
      size="lg"
      title="History of Sms"
      hide-footer
    >
      <modal-history-sms
        :id="historySms.id"
        :account-name="historySms.account_name"
        :lead-name="historySms.leadName"
      />
    </b-modal>

    <!-- modal HISTORY Email -->
    <b-modal
      id="modal-history-email"
      ok-only
      modal-class="modal-primary"
      title-class="text-white h4"
      centered
      size="lg"
      title="History of Email"
      hide-footer
    >
      <modal-history-email
        :id="historyEmail.id"
        :account-name="historyEmail.account_name"
        :lead-name="historyEmail.leadName"
      />
    </b-modal>
    <!-- Component Commons -->
    <component
      :is="modalData.programs.programSelected"
      v-if="modal.programs"
      :type-modal="modalData.programs.typeModal"
      :sales-client="saleClientP"
      :programs-all="programsAll"
      :users-services="usersServices"
      @closeModal="hideModalProgram"
      @changeProgram="openModalProgram"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// Componenrts
import BusinessModal from "@/views/crm/views/sales-made/components/modals/services/BuisnessModal.vue";
import CreditExpertsModal from "@/views/crm/views/sales-made/components/modals/services/credit-experts/CreditExpertsModal.vue";
import BoostCreditModal from "@/views/crm/views/sales-made/components/modals/services/BoostCreditModal.vue";
import GeneralSupportModal from "@/views/crm/views/sales-made/components/modals/services/GeneralSupportModal.vue";
import KeyBookModal from "@/views/crm/views/sales-made/components/modals/services/KeyBookModal.vue";
import ParagonModal from "@/views/crm/views/sales-made/components/modals/services/ParagonModal.vue";
import SpecialistModal from "@/views/crm/views/sales-made/components/modals/services/SpecialistModal.vue";
import TaxResearchModal from "@/views/crm/views/sales-made/components/modals/services/TaxResearchModal.vue";
import DebtSolutionModal from "@/views/crm/views/sales-made/components/modals/services/DebtSolutionModal.vue";
// Others
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";
import ModalAddService from "../modals/ModalAddService.vue";
// import ActionsTable from "@/views/crm/views/Lead/lead-table/ActionsTable.vue"
import ActionsTable from "../modals/ActionsTable.vue";

import ModalSendEmail from "@/views/commons/components/clients/modals/ModalSendEmail.vue";
import ModalSendSms from "@/views/commons/components/clients/modals/ModalSendSms.vue";
import ModalHistoryEmail from "../modals/ModalHistoryEmail.vue";
import ModalHistorySms from "../modals/ModalHistorySMS.vue";

import StatusPayment from "@/views/commons/components/clients/stylescomponents/StatusPayment.vue";

import dataFields from "./fields.data";
import dataFilter from "./filters.data";
import ClientService from "../services/clients.service";
import LeadService from "@/views/commons/components/lead-programs/service/lead.service";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import NcrLeadsService from "@/views/administration/views/ncr-leads/services/NcrLeads.service.js";
import FilterStatusAccount from "@/views/commons/components/clients/stylescomponents/FilterStatusAccount.vue";

export default {
  components: {
    FilterSlot,
    ActionsTable,
    ModalAddService,
    ModalSendEmail,
    ModalSendSms,
    ModalHistorySms,
    ModalHistoryEmail,

    StatusPayment,
    // Components Actions
    BusinessModal,
    CreditExpertsModal,
    BoostCreditModal,
    GeneralSupportModal,
    KeyBookModal,
    ParagonModal,
    SpecialistModal,
    TaxResearchModal,
    DebtSolutionModal,
    StatusAccount,
    NcrLeadsService,
    FilterStatusAccount,
  },
  data() {
    return {
      clientsSms: [],
      detailsClient: {},
      fields: dataFields,
      filters: dataFilter,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      modalData: {
        programs: {
          programSelected: "",
          typeModal: 0,
          salesClient: {},
        },
      },
      modal: {
        programs: false,
      },
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      historySms: {
        leadName: "",
        account_name: "",
        id: null,
      },
      historyEmail: {
        leadName: "",
        account_name: "",
        id: null,
      },
      saleClientP: {},
      clients: null,
      selectAll: 0, // total
      allClients: [],
      startPage: null,
      typeModal: null,
      actionsOptions: [],
      actionsOptionsPersonal: [],
      modalOpenAddService: false,
      modalSendSms: false,
      modalSendEmail: false,
      modalHistoryEmail: false,
      modalHistorySms: false,
      toPage: null,
      isBusy: false,
      selectAll: false,
      items: [],
      selected: [],
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      urlOriginalSoft: process.env.VUE_APP_ORIGINAL_SOFT,

      usersServices: [],
      typeAddChange: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    visibleFilters() {
      this.filters[2].visible = this.moduleParagon ? true : false;
      this.filters[3].visible = this.moduleParagon ? false : true;
      return this.filters.filter((filter) => filter.visible);
    },
    lengthClients() {
      return this.clientsSms.length == 0;
    },
    moduleList() {
      let module_id = this.moduleParagon === true ? 9 : 1;
      return module_id;
    },
    visibleFields() {
      this.fields[0].visible = this.currentUser.role_id !== 4;
      this.fields[9].visible =
        this.currentUser.role_id !== 3 || this.module_id === 2;
      this.fields[13].visible =
        this.currentUser.role_id === 1 ||
        this.currentUser.role_id === 2 ||
        this.currentUser.role_id === 3 ||
        this.currentUser.role_id === 17;
      this.fields[14].visible = this.currentUser.role_id !== 4;
      return this.fields.filter((column) => column.visible);
    },
    paymentType: {
      get() {
        return this.filters[5].model;
      },
      set(value) {
        this.filters[5].model = value;
      },
    },
    moduleParagon() {
      return this.$route.meta.moduleParagon;
    },
    paymentDay: {
      get() {
        return this.filters[6].model;
      },
      set(value) {
        this.filters[6].visible = value;
      },
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    pathNameSaleMade() {
      switch (this.moduleId) {
        case 12:
          return "sales-made-paragon-client-pending";
          break;
        case 3:
          return "sales-made-bussiness-client-pending";
          break;
      }
    },
  },

  watch: {
    paymentType(newVal) {
      if (newVal === 1) {
        this.paymentDay = true;
      } else {
        this.paymentDay = false;
        this.filters[6].model = 0;
      }
    },
  },
  async mounted() {
    this.actionsOptions.push("addService");
    this.actionsOptions.push("changeService");
    this.actionsOptionsPersonal.push("historySMS");
    this.actionsOptionsPersonal.push("historyEmail");
  },
  created() {
    this.$store.dispatch("NotificationStore/A_ACCOUNT_IS_RESPONSIBLE_CEO", {
      account_id: this.$route.params.idClient,
    });
    this.usersPrograms();
    this.usersAllPrograms();
    this.getAllStatusClientAccount();
  },
  methods: {
    async createEvent(data) {
      const response = await LeadService.createLeadEvent({
        user_id: this.currentUser.user_id,
        lead_id: data.lead_id,
      });
      this.event = response.id;
    },
    openModalSendSms() {
      this.modalSendSms = true;
    },
    openModalSendEmail() {
      this.modalSendEmail = true;
    },
    openModalHistoryEmail() {
      this.modalHistoryEmail = true;
    },
    openModalHistoryEmail(item) {
      this.historyEmail.id = item.account_id;
      this.historyEmail.account_name = item.account;
      this.historyEmail.leadName = item.lead_name;
      this.$bvModal.show("modal-history-email");
    },
    openModalHistorySms(item) {
      this.historySms.id = item.account_id;
      this.historySms.account_name = item.account;
      this.historySms.leadName = item.lead_name;
      this.$bvModal.show("modal-history-sms");
    },
    closeSendSms() {
      this.modalSendSms = false;
      this.modalSendEmail = false;
    },
    async usersPrograms() {
      try {
        const moduleId = this.moduleList === 9 ? 12 : 3;
        if (moduleId === 12) {
          const dataParagon = await ClientService.getAllAssessorsParagon();
          this.filters[2].options = dataParagon;
        } else {
          const paramsForBusinness = {
            idmodule: moduleId,
            iduser: this.currentUser.user_id,
            idrole: this.currentUser.role_id,
          };
          const dataBusinness = await ClientService.usersPrograms(
            paramsForBusinness
          );
          this.filters[3].options = dataBusinness;
        }
      } catch (error) {
        console.log(error);
      }
    },
    openModalProgram: async function (data) {
      if (data === undefined) return;
      if (data.captured.id && data.program.id && data.seller.id) {
        const response = await this.showConfirmSwal(
          "Are you sure?",
          "You won't be able to revert this!"
        );
        if (response.isConfirmed) {
          await this.addPreloader();
          const param = {
            prices: null,
            observation: "Service",
            contract: 1,
            program: data.program.id,
            fee: null,
            suggested: null,
            otherpricesp: null,
            event: this.event,
            json_noce: null,
            stateid: 0,

            // Diferents to add change Services
            account: this.detailsClient.account_id,
            captured: data.captured.id,
            seller: data.seller.id,
            type: this.typeAddChange,
            user_id: this.currentUser.user_id,
            module: this.moduleId,
            id_score: null,
            json_ce: null,
            type_client: "CLIENT",
          };
          try {
            let result = await LeadService.insertSaleMade(param);
            if (result.status == 200) {
              this.modalOpenAddService = false;
              this.$router
                .push({ name: this.pathNameSaleMade })
                .catch((err) => {
                  console.log(err);
                });
            }
          } catch (error) {
            this.showErrorSwal(
              "Please, verify if the client already has the service, otherwise contact support.",
              "Error"
            );
          } finally {
            this.removePreloader();
          }
        }
      }
    },
    async hideModalProgram(refresh) {
      if (refresh) {
        // await this.updateRow();
        this.showToast(
          "success",
          "top-right",
          "Success!",
          "CheckIcon",
          "Successful operation"
        );
      }

      this.modalData.programs.programSelected = "";
      this.modal.programs = false;
      this.removePreloader();
    },
    resetAllFilters() {
      this.filters.forEach((filter) => {
        filter.model = null;
      });
      this.filterPrincipal.model = null;
      this.$refs.clientsList.refresh();
    },
    modalChangeService: async function (data) {
      const result = await this.showConfirmSwal("Are you sure change service?");
      if (result.value) {
        this.typeAddChange = 2;
        await this.createEvent(data);
        this.modalGeneralService(data, 4);
      }
    },
    onRowDelete(id) {},
    onRowEdit: async function (data) {},
    onRowProcess(id) {},
    modalAddService: async function (data) {
      const result = await this.showConfirmSwal(
        "Are sure want to add new service?"
      );
      if (result.value) {
        this.typeAddChange = 1;
        await this.createEvent(data);
        this.modalGeneralService(data, 3);
      }
    },
    ...mapActions("crm-store", ["SET_PROGRAM", "SET_CATCHER", "SET_SELLER"]),
    modalGeneralService(data, type) {
      this.detailsClient = data;
      this.typeModal = type;
      this.SET_PROGRAM(null);
      this.SET_CATCHER(null);
      this.SET_SELLER(null);
      this.modalOpenAddService = true;
    },
    closeModalService() {
      this.modalOpenAddService = false;
    },
    usersAllPrograms: async function () {
      try {
        const data = await ClientService.usersAllPrograms({
          id: this.moduleList === 9 ? 12 : 3,
        });
        this.usersServices = data.data;
        this.usersServices.unshift({ id: 1, user_name: "CEO" });
      } catch (error) {
        console.log(error);
      }
    },
    searchPrograms: async function (lead_id) {
      try {
        const response = await ClientService.searchClientPayment({
          id: lead_id,
        });
        if (response.status == 200) {
          let programs = response.data;
          const data = await ClientService.programs();
          this.programsAll = data.data;
          programs.sort(function (a, b) {
            return a.program_id - b.program_id;
          });
          for (var i = 0; i < this.programsAll.length; i++) {
            for (var y = 0; y < programs.length; y++) {
              if (this.programsAll[i].id == programs[y].program_id) {
                this.programsAll.splice(i, 1);
              }
            }
          }
          var sper = this.currentUser.sale_permission;
          var spn = [];
          if (sper.length != this.programsAll.length) {
            sper.map((spe) => {
              this.programsAll.map((pro) => {
                if (spe.id == pro.id) {
                  spn.push(pro);
                }
              });
            });
            this.programsAll = spn;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async search(ctx) {
      try {
        let orderBy = 5;
        let sortDirection = "desc";
        if (ctx.sortBy === "lead_name") {
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
          orderBy = 2;
        } else if (ctx.sortBy === "account") {
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
          orderBy = 4;
        } else if (ctx.sortBy === "fee_charges") {
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
          orderBy = 42;
        } else if (ctx.sortBy === "pt") {
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
          orderBy = 48;
        } else if (ctx.sortBy === "mp") {
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
          orderBy = 43;
        } else if (ctx.sortBy === "lp") {
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
          orderBy = 40;
        } else if (ctx.sortBy === "ln") {
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
          orderBy = 41;
        } else if (ctx.sortBy === "creation_date") {
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
          orderBy = 5;
        }
        const params = {
          advisor: this.moduleParagon
            ? this.filters[2].model
            : this.currentUser.role_id == 1 || this.currentUser.role_id == 2
            ? this.filters[3].model
            : null,
          day: this.paymentDay,
          from: this.filters[0].model,
          order: sortDirection,
          orderby: orderBy,
          perPage: this.paginate.perPage,
          program: this.moduleList,
          rol_id: this.currentUser.role_id,
          // state: this.state,
          status: this.filters[4].model,
          text: this.filterPrincipal.model,
          to: this.filters[1].model,
          type: this.paymentType,
        };
        const data = await ClientService.getClientsParagon(
          params,
          ctx.currentPage
        );
        // data.data.data.map((data) => {
        //   // eslint-disable-next-line no-param-reassign
        //   data.accounts = JSON.parse(data.accounts)
        //   data.accounts.map((val) => {
        //     if (val.charges === null) val.charges = 0
        //   })
        // })
        const items = data.data.data;
        // Must return an array of items or an empty array if an error occurred
        this.clients = items;
        this.items = items;
        this.startPage = data.data.from;
        this.paginate.currentPage = data.data.current_page;
        this.paginate.perPage = data.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.data.last_page;
        this.totalData = data.data.total;
        this.totalRows = data.data.total;
        this.toPage = data.data.to;

        return this.items;
      } catch (error) {
        this.showErrorSwal(error);
        return [];
      }
    },
    selectedRow(data) {
      const index = this.selected.findIndex((select) => select.id === data.id);
      if (data.selected === true && index === -1) this.selected.push(data);
      else if (data.selected === false && index !== -1)
        this.selected.splice(index, 1);
    },
    selectedAll() {
      if (this.selectAll == 1) {
        let nameCl = [];
        this.clients.map((dato) => {
          nameCl.push({
            id: dato.account_id,
            name: dato.lead_name,
          });
        });
        let value = this.allClients.find(
          (element) => element.currentPage == this.paginate.currentPage
        );
        if (!value) {
          this.allClients.push({
            currentPage: this.paginate.currentPage,
            array: nameCl,
          });
        }
      }
      if (this.selectAll == 0) {
        const value = this.allClients.find(
          (element) => element.currentPage == this.paginate.currentPage
        );
        if (value) {
          const index = this.allClients.indexOf(value);
          this.allClients.splice(index, 1);
        }
      }
      this.clientsSms = [];
      for (let i = 0; i < this.allClients.length; i++) {
        this.clientsSms = [...this.clientsSms, ...this.allClients[i].array];
      }
    },
    async getAllStatusClientAccount() {
      this.filters[4].options = [];
      const { data } = await NcrLeadsService.getAllStatusClientAccount();
      this.filters[4].options.push({
        value: 0,
        label: "All",
        icon: "CircleIcon",
      });
      this.filters[4].options.push(...data);
    },
  },
};
</script>
