<template>
  <b-dropdown
    variant="link"
    no-caret
    :right="$store.state.appConfig.isRTL"
    boundary="viewport"
    :toggle-class="toogleClass"
  >
    <template #button-content>
      <feather-icon
        icon="MoreVerticalIcon"
        size="16"
        class="align-middle text-body"
      />
    </template>

    <!-- historySMS -->
    <b-dropdown-item
      v-if="options.includes('historySMS')"
      v-b-tooltip.hover.left="'History of SMS'"
      v-b-modal.modal-primary
      @click="onRowHistorySms(rowData)"
    >
      <feather-icon icon="RotateCcwIcon" />
      <span class="align-middle ml-50">History of SMS</span>
    </b-dropdown-item>

    <!-- historyEMAIL -->
    <b-dropdown-item
      v-if="options.includes('historyEmail')"
      v-b-tooltip.hover.left="'History of Email'"
      v-b-modal.modal-primary
      @click="onRowHistoryEmail(rowData)"
    >
      <feather-icon icon="RotateCcwIcon" />
      <span class="align-middle ml-50">History of Email</span>
    </b-dropdown-item>

    <!-- Add Service -->
    <b-dropdown-item
      v-if="options.includes('addService')"
      variant="warning"
      @click="onRowAddService(rowData)"
    >
      <feather-icon icon="FilePlusIcon" />
      <span class="align-middle ml-50">Add service</span>
    </b-dropdown-item>

    <!-- Change Service -->
    <b-dropdown-item
      v-if="options.includes('changeService')"
      :variant="$route.matched[0].meta.module == 26 ? 'warning' : 'success'"
      @click="onRowChangeService(rowData)"
    >
      <feather-icon icon="EditIcon" />
      <span class="align-middle ml-50">Change service</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import VueGoogleAutocomplete from "vue-google-autocomplete";
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BButton,
    VueGoogleAutocomplete,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    rowData: {
      required: true,
    },
    toogleClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    currentUser() {
      return this.$store.getters["auth/currentUser"];
    },
  },
  methods: {

    onRowHistorySms(item) {
      this.$emit("modalHistorySmsOpen", item);
    },

    onRowHistoryEmail(item) {
      this.$emit("modalHistoryEmailOpen", item);
    },

    onRowAddService(item) {
      this.$emit("modalAddService", item);
    },
    onRowChangeService(item) {
      this.$emit("modalChangeService", item);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.sidebar-xl {
  width: 90rem;
}
</style>
